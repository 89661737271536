<template>
  <section class="section-1 bg-block mb-4">
    <h4 v-if="churchName !== ``">{{ churchName }}</h4>
    <h4 v-if="individualName !== ``">{{ individualName }}</h4>
    <h1 class="sm mb-4">
      <span v-if="!this.editedPhone.phn_key">{{ i18n['phone-form'].tcAddPhoneOrFax }}</span>
      <span v-if="!!this.editedPhone.phn_key">{{ i18n['phone-form'].tcEditPhoneOrFax }}</span>
    </h1>
    <div class="body">
      <b-form>
        <b-form-group class="form-element">
          <div class="g-row">
            <div class="g-col-1 d-flex">
              <b-form-group class="form-element">
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['phone-form'].tcCategory }}</h3>
                <b-form-group>
                  <b-form-radio-group
                    id="radios-1"
                    v-model="editedPhone.category"
                    :options="categoryOptions"
                    name="radios-1"
                  ></b-form-radio-group>
                </b-form-group>
                <h3 class="flex-1 mr-3 text-left font-style-2">{{ i18n['phone-form'].tcIsPrimary }}</h3>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="editedPhone.primary_phone_flag"
                  name="checkbox-1"
                  value="true"
                  class="mb-3"
                  unchecked-value="false"
                ></b-form-checkbox>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['phone-form'].tcNumber }}*</h3>
                <b-form-input
                  id="type-text6"
                  v-model="editedPhone.phone_number"
                  class="flex-0 mb-3 g-select"
                  type="text"
                  maxlength="75"
                ></b-form-input>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['phone-form'].tcExtension }}</h3>
                <b-form-input
                  id="type-text7"
                  v-model="editedPhone.extension"
                  class="flex-0 mb-3 g-select"
                  type="text"
                  maxlength="75"
                ></b-form-input>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['phone-form'].tcType }}*</h3>
                <b-form-select
                  v-model="editedPhone.pht_key"
                  :options="editedPhone.category === 'phone' ? optionsPhoneTypesTranslated : optionsFaxTypesTranslated"
                  class="form-control g-select flex-0 mr-3 mb-3"
                >
                  <template slot="first"></template>
                </b-form-select>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['phone-form'].tcCountry }}</h3>
                <b-form-select
                  v-model="editedPhone.cnt_key"
                  :options="optionsCountries"
                  class="form-control g-select flex-0 mr-3 mb-3"
                >
                  <template slot="first"></template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="g-col-2"></div>
          </div>
        </b-form-group>
      </b-form>
    </div>
    <div class="body">
      <div class="d-flex">
        <b-button @click="handleSaveClick" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
          {{ i18n['phone-form'].tcSave }}
        </b-button>
        <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">
          {{ i18n['phone-form'].tcCancel }}
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'phone-form',
  mixins: [translationMixin],
  data() {
    return {
      editedPhone: {
        phn_key: '',
        pxe_key: '',
        category: 'phone',
        pht_key: '',
        phone_type: null,
        phone_number: '',
        extension: '',
        unlisted_flag: false,
        IsPhone: true,
        IsFax: false,
        country: 'United States',
        cnt_key: null,
        primary_phone_flag: false,
      },
      optionsFaxTypesTranslated: {},
      optionsPhoneTypesTranslated: {},
    }
  },
  props: {
    churchName: {
      type: String,
      default: '',
    },
    churchKey: {
      type: String,
      default: '',
    },
    individualName: {
      type: String,
      default: '',
    },
    individualKey: {
      type: String,
      default: '',
    },
    userKey: {
      type: String,
      default: '',
    },
    phone: {
      type: Object,
      default: () => {
        return {
          phn_key: '',
          pxe_key: '',
          pht_key: '',
          phone_type: '',
          phone_number: '',
          extension: '',
          unlisted_flag: false,
          primary_phone_flag: 0,
          unlisted_word: '',
          primary_phone_word: '',
          number_masked: '',
          IsPhone: true,
          IsFax: false,
          number_masked_extension: '',
        }
      },
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'phone-form': {
            tcAddPhoneOrFax: 'Add Phone/Fax',
            tcCancel: 'Cancel',
            tcCategory: 'Category',
            tcCountry: 'Country',
            tcEditPhoneOrFax: 'Edit Phone/Fax',
            tcErrorDuringSaveProcess: 'An error occurred during the save process.',
            tcErrorPhoneNumInvalid:
              'Phone Number is not valid. Verify the number is entered correctly before attempting to save.',
            tcErrorPhoneNumRequired: 'Phone Number is required. Please complete before attempting to save.',
            tcErrorPhoneTypeRequired: 'Type is required. Please complete before attempting to save.',
            tcExtension: 'Extension',
            tcFax: 'Fax',
            tcIsPrimary: 'IsPrimary?',
            tcNumber: 'Number',
            tcOk: 'Ok',
            tcPhone: 'Phone',
            tcSave: 'Save',
            tcSelectCountry: 'Select a country',
            tcSelectType: 'Select a type',
            tcThe: 'The',
            tcType: 'Type',
            tcWasNotSaved: 'was not saved.',
            tcWasSaved: 'was saved.'
          },
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedPhone: 'user/setSelectedPhone',
      loadPhoneFormOptions: 'membership/loadPhoneFormOptions',
      savePhone: 'membership/savePhone',
    }),
    async handleSaveClick() {
      if (this.editedPhone.phone_number === '') {
        this.$swal({
          icon: 'error',
          text: this.i18n['phone-form'].tcErrorPhoneNumRequired,
          confirmButtonText: this.i18n['phone-form'].tcOk,
        }).then((result) => {
          return
        })
      } else if (!this.verifyUnmaskedNumber()) {
        this.$swal({
          icon: 'error',
          text: this.i18n['phone-form'].tcErrorPhoneNumInvalid,
          confirmButtonText: this.i18n['phone-form'].tcOk,
        }).then((result) => {
          return
        })
      } else if (this.editedPhone.pht_key === null) {
        this.$swal({
          icon: 'error',
          text: this.i18n['phone-form'].tcErrorPhoneTypeRequired,
          confirmButtonText: this.i18n['phone-form'].tcOk,
        }).then((result) => {
          return
        })
      } else {
        var payload = {
          member_no: this.demograph.member_number,
          category: this.editedPhone.category,
          phn_key: this.editedPhone.phn_key,
          phn_number: this.editedPhone.phone_number,
          nag_key: '',
          phn_user: this.userKey,
          phn_delete_flag: false,
          phn_cnt_key: this.editedPhone.cnt_key,
          pxe_key: this.editedPhone.pxe_key,
          pxe_extension: this.editedPhone.extension,
          pxe_entity_key: !!this.individualKey ? this.individualKey : this.churchKey,
          pxe_pht_key: this.editedPhone.pht_key,
          pxe_unlisted_flag: !!this.editedPhone.pxe_unlisted_flag ? this.editedPhone.pxe_unlisted_flag : false,
          pxe_user: this.userKey,
          primary_phone_flag: this.isPrimaryFlag(),
          allow_confirm_primary_procedure_run: false,
        }

        let result = true
        try {
          await Promise.all([this.setLoadingStatus(true), (result = await this.savePhone(payload))]).then(() => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: result ? 'success' : 'error',
              text: result ? this.phoneSaved : this.phoneNotSaved,
              confirmButtonText: this.i18n['phone-form'].tcOk,
            }).then((result) => {
              if (result.value) {
                this.handleCancelClick()
              }
            })
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async handleCancelClick() {
      await Promise.all([await this.setSelectedPhone({})]).then(() => {
        this.$router.go(-1)
      })
    },
    isPrimaryFlag() {
      if (
        this.individualName.length > 0 &&
        this.individualCommunicationsData.phones &&
        this.individualCommunicationsData.phones.length === 0
      ) {
        return true
      } else if (
        this.churchName.length > 0 &&
        this.churchCommunicationsData.phones &&
        this.churchCommunicationsData.phones.length === 0
      ) {
        return true
      } else if (
        this.$route.path.split('/')[2] !== 'cm' &&
        this.churchName.length > 0 &&
        this.locationCommunicationsData.phones &&
        this.locationCommunicationsData.phones.length === 0
      ) {
        return true
      } else {
        return this.editedPhone.primary_phone_flag
      }
    },
    verifyUnmaskedNumber() {
      let phoneNumber = this.editedPhone.phone_number
      let unmaskedNumber = ''
      let invalidCharacterFound = false

      //This string contains the only valid characters other than digits in an unmasked number.
      var validUnmaskedCharacters = ' -()+'
      var validUnmaskedCharacterCodes = []

      for (let index = 0; index < validUnmaskedCharacters.length; index++) {
        validUnmaskedCharacterCodes.push(validUnmaskedCharacters.charCodeAt(index))
      }

      var charCodeZero = '0'.charCodeAt()
      var charCodeNine = '9'.charCodeAt()

      for (let index = 0; !invalidCharacterFound && index < phoneNumber.length; index++) {
        let charCode = phoneNumber.charCodeAt(index)

        // If the character is within the allowed character list for unmasked phone
        // numbers, add the value straight up into the unmasked number.
        if (
          (charCode >= charCodeZero && charCode <= charCodeNine) ||
          validUnmaskedCharacterCodes.indexOf(charCode) > -1
        ) {
          unmaskedNumber += phoneNumber[index]
        } else if (phoneNumber[index].match(/^[A-Za-z]$/)) {
          // If an alpha character is found replace the mask with a valid digit.
          unmaskedNumber += '0'
        } else {
          // All other characters are invalid, and should cause the verification to fail.
          invalidCharacterFound = true
        }
      }

      // If the number contained no invalid characters, verify the unmasked value is
      // a valid phone number. Here is a list of rules applied by this regex:
      // 1) Can start with a "+", digit, or "("; any other character is invalid
      // 2) Only single spaces, combination of parentheses, or dashes may separate number series
      // 3) Spaces and dashes cannot persist inside of parentheses, and parentheses cannot be nested
      // 4) Every number series (except for the very first series) must contain at least two digits
      return (
        !invalidCharacterFound &&
        unmaskedNumber.match(/^\+?((?:\d+\s{1})|(?:\d+\-{1}\d+)*|(?:\(\d+\)\s{0,1})|(?:\d+))*$/)
      )
    },
  },
  computed: {
    ...mapGetters({
      churchCommunicationsData: 'churchMinistry/churchCommunicationsData',
      convertedPhoneFormOptions: 'membership/convertedPhoneFormOptions',
      demograph: 'user/demograph',
      individualCommunicationsData: 'membership/individualCommunicationsData',
      locationCommunicationsData: 'scriptureDistribution/locationCommunicationsData',
      phoneFormOptions: 'membership/phoneFormOptions',
      userIndKey: 'user/userId',
    }),
    // FormOptions
    categoryOptions() {
      return [
        { text: this.i18n['phone-form'].tcPhone, value: 'phone' },
        { text: this.i18n['phone-form'].tcFax, value: 'fax' },
      ]
    },
    optionsCountries() {
      return [
        {
          value: null,
          text: this.i18n['phone-form'].tcSelectCountry,
          disabled: true,
        },
        ...this.convertedPhoneFormOptions.countries,
      ]
    },
    optionsPhoneTypes() {
      return [ ...this.convertedPhoneFormOptions.phoneTypes ]
    },
    optionsFaxTypes() {
      return [ ...this.convertedPhoneFormOptions.faxTypes ]
    },
    // END FormOptions
    phoneNotSaved() {  // Returns text like: 'An error occurred... The `phoneCategory` was not saved.'
      const {tcErrorDuringSaveProcess, tcThe, tcWasNotSaved} = this.i18n['phone-form']
      return `${tcErrorDuringSaveProcess} ${tcThe} ${this.editedPhone.category} ${tcWasNotSaved}`
    },
    phoneSaved() {  // Returns text like: 'The `phoneCategory` was saved.'
      return `${this.i18n['phone-form'].tcThe} ${this.editedPhone.category === 'phone' ? 
              this.optionsPhoneTypesTranslated.filter(o => o.value === this.editedPhone.pht_key)[0].text : 
              this.optionsFaxTypesTranslated.filter(o => o.value === this.editedPhone.pht_key)[0].text} 
              ${this.i18n['phone-form'].tcWasSaved}`
              
        
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      if (this.phoneFormOptions.countries.length === 0 || this.phoneFormOptions.phoneTypes.length === 0) {
        await Promise.all([this.loadPhoneFormOptions()])
      }
      this.editedPhone.phn_key = this.phone.phn_key ? this.phone.phn_key : ''
      this.editedPhone.pxe_key = this.phone.pxe_key ? this.phone.pxe_key : ''
      this.editedPhone.category = this.phone.phn_key && this.phone.IsFax ? 'fax' : 'phone'
      this.editedPhone.pht_key = this.phone.pht_key ? this.phone.pht_key : null
      this.editedPhone.phone_type = this.phone.phone_type ? this.phone.phone_type : null
      this.editedPhone.phone_number = this.phone.phone_number ? this.phone.phone_number : ''
      this.editedPhone.extension = this.phone.extension ? this.phone.extension : ''
      this.editedPhone.unlisted_flag = this.phone.unlisted_flag ? this.phone.unlisted_flag : false
      this.editedPhone.IsPhone = this.phone.IsPhone ? this.phone.IsPhone : true
      this.editedPhone.IsFax = this.phone.IsFax ? this.phone.IsFax : false
      this.editedPhone.cnt_key = this.phone.cnt_key
        ? this.phone.cnt_key
        : this.convertedPhoneFormOptions.countriesByCamp[0].value
      this.editedPhone.primary_phone_flag = !!this.phone.primary_phone_flag

      const firstOption = this.i18n['phone-form'].tcSelectType || 'Select Type'
      this.translateDropdownOptions(
        [
          {node: 'fax-types', store: 'optionsFaxTypes', disabledPlaceholder: firstOption},
          {node: 'phone-types', store: 'optionsPhoneTypes', disabledPlaceholder: firstOption}
        ],
        'i18n'
      )
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;
  @include breakpoint(sm) {
    width: 24px;
  }
  input {
    order: 2;
  }
  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;
    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;
    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .g-col-2 {
    max-width: 247px;
    width: 100%;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
